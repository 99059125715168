<template>
  <div v-if="selectedExprt">
    <form>
      <!-- <h1>Culture Form</h1> -->
      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="fillInCheck"
              id="fill-in-check"
            />
            <label class="form-check-label text-primary" for="fill-in-check">
              Fill with existing culture
            </label>
          </div>
        </div>
        <div v-if="fillInCheck" class="form-group col-md-6">
          <label for="sel-culture-fill-in">Existing Culture:</label>
          <VueMultiselect
            id="sel-culture-fill-in"
            v-model="fillInCulture"
            :options="cultures"
            :loading="isLoadingCultures"
            :internal-search="false"
            :multiple="false"
            :close-on-select="true"
            :options-limit="10"
            :custom-label="cultureInfo"
            placeholder="Type to search"
            label="olab_id"
            track-by="olab_id"
            @search-change="getCultures"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Culture Type:</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="inoculumType"
              id="inpCheckInoculum-add"
            />
            <label class="form-check-label" for="inpCheckInoculum-add">
              Inoculum
            </label>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="sel-culture-strain-add">Strain:</label>
          <span v-if="strain == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div v-if="cultureStage === '00'">
            <VueMultiselect
              id="sel-culture-strain-add"
              v-model="strain"
              :options="strains"
              :loading="isLoadingStrains"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getStrains"
            />
          </div>
          <div v-else>
            <VueMultiselect
              id="sel-culture-strain-add"
              v-model="strain"
              :options="strainCandidates"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="sel-strain-source-type-add">Strain Source Type:</label>
          <span
            v-if="strainSourceType == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-strain-source-type-add"
              v-model="strainSourceType"
              :options="strainSourceTypeSettingModel"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="strainSourceType">{{ strainSourceType }}</p> -->
        </div>
        <div class="form-group col-md-4">
          <label for="sel-strain-confirmation-method-add"
            >Strain Confirmation Method:</label
          >
          <span
            v-if="strainConfirmationMethod == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-strain-confirmation-method-add"
              v-model="strainConfirmationMethod"
              :options="strainConfirmationMethodSettingModel"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="strainConfirmationMethod">{{ strainConfirmationMethod }}</p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-media-add">Media:</label>
          <span v-if="media == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-media-add"
              v-model="media"
              :options="medias"
              :loading="isLoadingMedias"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getMedias"
            />
          </div>
        </div>
      </div>
      <!-- <h1>Use productionStage = {{ productionStage }}</h1> -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="containerRadios"
              id="multiwellRadio"
              value="multiwell"
              v-model="containerType"
            />
            <label class="form-check-label" for="multiwellRadio">
              Multiwell
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="containerRadios"
              id="plateRadio"
              value="plate"
              v-model="containerType"
            />
            <label class="form-check-label" for="plateRadio"> Plate </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="containerRadios"
              id="tubeRadio"
              value="tube"
              v-model="containerType"
            />
            <label class="form-check-label" for="tubeRadio"> Tube </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="containerRadios"
              id="flaskRadio"
              value="flask"
              v-model="containerType"
            />
            <label class="form-check-label" for="flaskRadio"> Flask </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="containerRadios"
              id="bioreactorRadio"
              value="bioreactor"
              v-model="containerType"
            />
            <label class="form-check-label" for="bioreactorRadio">
              Bioreactor
            </label>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-container-add"
            >{{ containerTypeName }}:</label
          >
          <span
            v-if="container === null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-container-add"
              v-model="container"
              :options="containers"
              :loading="isLoadingContainers"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="containerInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getContainers"
            />
          </div>
        </div>
        <div v-if="containerType === 'bioreactor'" class="form-group col-md-6">
          <div class="form-row">
            <div class="form-group col-lg-4">
              <label for="inp-culture-bioreactor-temp-add">Temperature:</label>
              <div v-if="container" class="input-group">
                <input
                  id="inp-culture-bioreactor-temp-add"
                  type="number"
                  class="form-control"
                  v-model="container.tm"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> &#8451; </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-lg-4">
              <label for="inp-culture-bioreactor-speed-add">Speed:</label>
              <div v-if="container" class="input-group">
                <input
                  id="inp-culture-bioreactor-speed-add"
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="container.rpm"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> rpm </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-lg-4">
              <label for="inp-culture-bioreactor-air-add">Air(vvm):</label>
              <div v-if="container" class="input-group">
                <input
                  id="inp-culture-bioreactor-air-add"
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="container.air_vvm"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> L/min </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
          </div>
        </div>
        <div v-else class="form-group col-md-6">
          <div class="row">
            <div v-if="containerType !== 'multiwell'" class="my-2 col-md-4">
              <label>Type:</label>
              <div v-if="container">
                {{ container.type }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div
              v-if="containerType !== 'plate' && containerType !== 'multiwell'"
              class="my-2 col-md-5"
            >
              <label>Size:</label>
              <div v-if="container">
                {{ container.size }} {{ containerUnit }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div v-else class="col-md-8">
              <!-- This is a plate case (round or rectangle) -->
              <div v-if="container && container.dimension">
                <div v-if="containerType === 'plate'">
                  <div v-if="container.type === 'round'" class="row">
                    <div class="my-2 col">
                      <label>Diameter:</label>
                      <div>
                        {{ container.dimension.diameter }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Height:</label>
                      <div>
                        {{ container.dimension.height }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                  </div>
                  <div v-if="container.type === 'rectangle'" class="row">
                    <div class="my-2 col">
                      <label>Width:</label>
                      <div>
                        {{ container.dimension.width }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Length:</label>
                      <div>
                        {{ container.dimension.length }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Height:</label>
                      <div>
                        {{ container.dimension.height }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="containerType === 'multiwell'">
                  <div class="row">
                    <div class="my-2 col">
                      <label>Number of wells:</label>
                      <div>
                        {{ container.dimension.number_of_wells }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Volume/well:</label>
                      <div>{{ container.dimension.volume_per_well }}mL</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="my-2 col">
                      <label>Width:</label>
                      <div>
                        {{ container.dimension.width }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Length:</label>
                      <div>
                        {{ container.dimension.length }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                    <div class="my-2 col">
                      <label>Height:</label>
                      <div>
                        {{ container.dimension.height }}
                        {{ container.dimension.unit }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="containerType !== 'bioreactor'" class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-incubator-add">Incubator:</label>
          <span
            v-if="incubator == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-incubator-add"
              v-model="incubator"
              :options="incubators"
              :loading="isLoadingIncubators"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getIncubators"
            />
          </div>
          <!-- <p>{{ incubator }}</p> -->
        </div>
        <div class="form-group col-md-6">
          <div class="form-row">
            <div class="form-group col-md-5">
              <label for="inp-culture-incubator-temp-add"
                >Temperature(&#8451;):</label
              >
              <div v-if="incubator" class="input-group">
                <input
                  id="inp-culture-incubator-temp-add"
                  type="number"
                  class="form-control"
                  v-model="incubator.tm"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> &#8451; </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-md-3">
              <label>Type:</label>
              <div v-if="incubator">
                {{ incubator.type }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-md-4">
              <label for="inp-culture-incubator-speed-add">Speed(rpm):</label>
              <div
                v-if="incubator && incubator.type === 'Shaker'"
                class="input-group"
              >
                <input
                  id="inp-culture-incubator-speed-add"
                  type="number"
                  class="form-control"
                  v-model="incubator.rpm"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> rpm </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputStartDate">Start Date</label>
          <div class="input-group">
            <input
              id="inputStartDate"
              type="date"
              class="form-control"
              v-bind:class="validState(startDate)"
              :value="startDate"
              @input.prevent="updateStartDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputStartTime">Start Time</label>
          <div class="input-group">
            <input
              id="inputStartTime"
              type="time"
              class="form-control"
              v-bind:class="validState(startTime)"
              :value="startTime"
              @input.prevent="updateStartTime"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputEndDate">End Date</label>
          <div class="input-group">
            <input
              id="inputEndDate"
              type="date"
              class="form-control"
              :value="endDate"
              @input.prevent="updateEndDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputEndTime">End Time</label>
          <div class="input-group">
            <input
              id="inputEndTime"
              type="time"
              class="form-control"
              :value="endTime"
              @input.prevent="updateEndTime"
            />
          </div>
        </div>
        <div class="form-group col-md-6">
          <label>Culture Time:</label>
          <div class="text-info ml-2">
            {{ formatCultureTime }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div v-if="containerType !== 'plate'" class="form-group col-md-3">
          <label for="inputInitialOD">Initial OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputInitialOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="initialOD"
              @input.prevent="updateInitialOD"
            />
          </div>
        </div>
        <div
          v-if="containerType !== 'bioreactor' && containerType !== 'plate'"
          class="form-group col-md-3"
        >
          <label for="inputFinalOD">Final OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputFinalOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="finalOD"
              @input.prevent="updateFinalOD"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialPHLevel">Initial pH Level</label>
          <div class="input-group">
            <input
              id="inputInitialPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="initialPHLevel"
              @input.prevent="updateInitialPHLevel"
            />
          </div>
        </div>
        <div
          v-if="containerType !== 'bioreactor' && containerType !== 'plate'"
          class="form-group col-md-3"
        >
          <label for="inputFinalPHLevel">Final pH Level</label>
          <div class="input-group">
            <input
              id="inputFinalPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="finalPHLevel"
              @input.prevent="updateFinalPHLevel"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialTotalCarbon">Initial Total Carbon</label>
          <div class="input-group">
            <input
              id="inputInitialTotalCarbon"
              type="Number"
              min="0"
              class="form-control"
              :value="initialTotalCarbon"
              @input.prevent="updateInitialTotalCarbon"
            />
            <!-- Unit is in gram -->
            <div class="input-group-append">
              <span class="input-group-text"> g </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialVolume">Initial Volume</label>
          <div class="input-group">
            <input
              id="inputInitialVolume"
              type="Number"
              min="0"
              class="form-control"
              :value="initialVolume"
              @input.prevent="updateInitialVolume"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInoculumSize">Inoculum Size</label>
          <div class="input-group">
            <input
              id="inputInoculumSize"
              type="Number"
              min="0"
              class="form-control"
              :value="inoculumSize"
              @input.prevent="updateInoculumSize"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
        <label for="inp-culture-imagefile-add">Growth</label>
        <span class="text-warning mx-1">TODO: Work in progress</span>
        <div class="input-group">
          <input type="file" id="inp-culture-imagefile-add" accept="image/*" />
        </div>
      </div> -->
      <div class="form-group">
        <label for="inputNote">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          style="text-align: left"
          rows="3"
          id="inputNote"
          v-model="note"
        ></textarea>
      </div>

      <!-- Production data upload is only for bioreactor -->
      <div v-if="containerType === 'bioreactor'" class="text-primary">
        Production data can be uploaded once culture record is created
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <div class="form-row justify-content-end">
        <button
          type="button"
          v-bind:disabled="itemAddIsDisabled == true"
          class="btn btn-primary mr-2"
          v-on:click="addItem()"
        >
          <span
            v-if="localStatusObj.progress"
            class="spinner-border spinner-border-sm text-light"
          ></span>
          Add
        </button>
        <button
          type="button"
          class="btn btn-secondary mr-2"
          v-on:click="cancelAdd()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabUtils } from "@/olab/olabutils";
import VueMultiselect from "vue-multiselect";
import { strainSourceTypeSettingModel } from "@/assets/data/strain_source_type_setting";
import { strainConfirmationMethodSettingModel } from "@/assets/data/strain_confirmation_method_setting";

export default {
  name: "CultureAddForm",
  data() {
    return {
      fillInCheck: false,
      fillInCulture: null,
      strainSourceTypeSettingModel,
      strainConfirmationMethodSettingModel,
      strainCandidates: [],
      selectedType: "culture",
      selectedTypeName: "Culture",
      inoculumType: false,
      strainSourceType: null,
      strainConfirmationMethod: null,
      cultureType: "culture",
      containerType: "flask",
      container: null,
      media: null,
      strain: null,
      incubator: null,
      note: null,
      imageFile: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      cultureTime: 0,
      startDateISO: null,
      endDateISO: null,
      inoculumSize: 0,
      initialOD: 0,
      finalOD: 0,
      initialPHLevel: 0,
      finalPHLevel: 0,
      initialVolume: 0,
      initialTotalCarbon: 0,
      containers: [],
      medias: [],
      strains: [],
      incubators: [],
      cultures: [],
      isLoadingCultures: false,
      isLoadingContainers: false,
      isLoadingMedias: false,
      isLoadingStrains: false,
      isLoadingIncubators: false,
      item: new OlabItem(),
      invalidFB: OlabItem.getInvalidFeedbacks(),
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: { VueMultiselect },
  props: ["user", "selectedExprt", "cultureStage", "productionStage"],
  emits: ["doneCultureAdd"],
  async mounted() {
    // console.log("CultureAdd mounted: selectedExprt =", this.selectedExprt);
    // console.log("CultureAdd mounted: cultureStage =", this.cultureStage);
    await this.getContainers("");
    await this.getMedias("");
    if (this.cultureStage === "00") {
      await this.getStrains("");
    } else {
      await this.setupStrainCandidates();
    }
    await this.getIncubators("");

    // Set default strainConfirmationMethod to none method
    if (this.strainConfirmationMethodSettingModel) {
      this.strainConfirmationMethod =
        this.strainConfirmationMethodSettingModel.find(
          (tt) => tt.key_name === "none"
        );
    }
  },
  computed: {
    itemAddIsDisabled() {
      return !(
        OlabSanity.isValid(this.media) &&
        OlabSanity.isValid(this.strain) &&
        OlabSanity.isValid(this.strainSourceType) &&
        OlabSanity.isValid(this.strainConfirmationMethod) &&
        OlabSanity.isValid(this.container) &&
        (this.container.olab_type === "bioreactor" ||
          OlabSanity.isValid(this.incubator)) &&
        OlabSanity.isValid(this.startDate) &&
        OlabSanity.isValid(this.startTime)
      );
    },
    containerTypeName() {
      return (
        this.containerType.charAt(0).toUpperCase() + this.containerType.slice(1)
      );
    },
    formatCultureTime() {
      // cultureTime is in minutes
      return OlabDate.formatDiffTime(this.cultureTime);
    },
    containerUnit() {
      let sym = "--";
      if (this.container) {
        if (this.containerType === "plate") {
          sym = "ml";
        } else {
          sym = OlabUtils.getUnitSym(this.container.unit);
        }
      }
      return sym;
    }
  },
  methods: {
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    cultureInfo({ olab_id, name }) {
      return `${name} [${olab_id}]`;
    },
    containerInfo({ olab_type, name, type, dimension, size, unit }) {
      let result = "";
      if (olab_type === "plate") {
        if (type === "round") {
          result = `${name}: [${type} (${dimension.diameter}, ${dimension.height}) ${dimension.unit}]`;
        } else {
          result = `${name}: [${type} (${dimension.width}, ${dimension.length}, ${dimension.height}) ${dimension.unit}]`;
        }
      } else if (olab_type === "multiwell") {
        result = `${name}: [${dimension.number_of_wells}, ${dimension.volume_per_well}mL]`;
      } else if (olab_type === "bioreactor") {
        result = `${name}: [${size} ${unit}]`;
      } else {
        // flask, tube or other cases
        result = `${name}: [${type}, ${size} ${unit}]`;
      }
      return result;
    },
    async getCultures(query) {
      // console.log("getCultures: query = ", query);
      this.isLoadingCultures = true;
      const searchBody = OlabSearch.createSearchBody("culture", query, "merge");
      // Restrict cultures query to selected experiment only
      searchBody.auxID = this.selectedExprt.olab_id;
      searchBody.auxID2 = this.cultureStage;
      this.cultures = await OlabAxios.getCultures(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingCultures = false;
    },
    async getContainers(query) {
      // console.log("getContainers: query = ", query);
      const cType = this.containerType;
      this.isLoadingContainers = true;
      const searchBody = OlabSearch.createSearchBody(cType, query, "merge");
      if (this.containerType === "bioreactor") {
        this.containers = await OlabAxios.getBioreactors(
          searchBody,
          this.localStatusObj
        );
      } else if (this.containerType === "flask") {
        this.containers = await OlabAxios.getFlasks(
          searchBody,
          this.localStatusObj
        );
      } else if (this.containerType === "plate") {
        this.containers = await OlabAxios.getPlates(
          searchBody,
          this.localStatusObj
        );
      } else if (this.containerType === "tube") {
        this.containers = await OlabAxios.getTubes(
          searchBody,
          this.localStatusObj
        );
      } else if (this.containerType === "multiwell") {
        this.containers = await OlabAxios.getMultiwells(
          searchBody,
          this.localStatusObj
        );
      }
      this.isLoadingContainers = false;
    },
    async getMedias(query) {
      // console.log("getMedias: query = ", query);
      this.isLoadingMedias = true;
      const searchBody = OlabSearch.createSearchBody("media", query, "merge");
      this.medias = await OlabAxios.getMedias(searchBody, this.localStatusObj);
      this.isLoadingMedias = false;
    },
    async getStrains(query) {
      // console.log("getStrains: query = ", query);
      this.isLoadingStrains = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingStrains = false;
    },
    async getIncubators(query) {
      // console.log("getIncubators: query = ", query);
      this.isLoadingIncubators = true;
      const searchBody = OlabSearch.createSearchBody(
        "incubator",
        query,
        "merge"
      );
      this.incubators = await OlabAxios.getIncubators(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingIncubators = false;
    },
    async setupStrainCandidates() {
      const prevCS = parseInt(this.cultureStage) - 1;
      if (this.selectedExprt && prevCS >= 0) {
        const strainIDs = await OlabAxios.getStrainCandidates(
          this.selectedExprt.olab_id,
          prevCS,
          this.localStatusObj
        );
        // console.log("strainIDs =", strainIDs);
        this.strainCandidates = await OlabAxios.getStrainsByOlabIDs(
          strainIDs,
          this.localStatusObj
        );
        // console.log("strainCandidates =", this.strainCandidates);
      }
    },
    // Computed culture time in minutes
    computeCultureTime() {
      let time = 0;
      if (this.startDate && this.startTime && this.endDate && this.endTime) {
        const sDateTime = OlabDate.createDateObj(
          this.startDate,
          this.startTime
        );
        const eDateTime = OlabDate.createDateObj(this.endDate, this.endTime);
        time = OlabDate.computeDiffTime(sDateTime, eDateTime);
      }
      this.cultureTime = time;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    async loadCultureData() {
      if (this.fillInCulture && this.fillInCulture.olab_type === "culture") {
        const itemData = await OlabAxios.getCultureData(
          this.fillInCulture,
          this.localStatusObj
        );
        // console.log("itemData =", itemData);
        // Set strain_source_type
        if (this.strainSourceTypeSettingModel && this.fillInCulture) {
          this.strainSourceType = this.strainSourceTypeSettingModel.find(
            (tt) => tt.key_name === this.fillInCulture.strain_source_type
          );
        }
        // Set strain_confirmation_method
        if (this.strainConfirmationMethodSettingModel && this.fillInCulture) {
          this.strainConfirmationMethod =
            this.strainConfirmationMethodSettingModel.find(
              (tt) =>
                tt.key_name === this.fillInCulture.strain_confirmation_method
            );
        }
        this.media = itemData.media;
        this.strain = itemData.strain;
        this.containerType = itemData.culture
          ? itemData.culture.container.olab_type
          : "flask";
        // Need to reload containers list
        await this.getContainers("");
        this.container = itemData.culture ? itemData.culture.container : null;
        // console.log("** container =", this.container);
        this.cultureType = itemData.culture
          ? itemData.culture.culture_type
          : "culture";
        this.inoculumType = this.cultureType === "inoculum" ? true : false;
        this.incubator = itemData.culture ? itemData.culture.incubator : null;
        this.inoculumSize = itemData.culture.inoculum_size;
        this.initialOD = itemData.culture.initial_OD;
        this.finalOD = itemData.culture.final_OD;
        this.initialPHLevel = itemData.culture.initial_ph_level;
        this.finalPHLevel = itemData.culture.final_ph_level;
        this.initialVolume = itemData.culture.initial_volume;
        this.initialTotalCarbon = itemData.culture
          ? itemData.culture.initial_total_carbon
          : 0;
        this.note = itemData.culture.note;
        if (itemData.culture && itemData.culture.start_date) {
          const sDate = OlabDate.createDateObjWithISOString(
            itemData.culture.start_date
          );
          this.startDate = OlabDate.formatYMD(sDate, false);
          this.startTime = OlabDate.formatHM(sDate, false);
          this.startDateISO = this.computeDateISOStr(
            this.startDate,
            this.startTime
          );
        }
        if (itemData.culture && itemData.culture.end_date) {
          const eDate = OlabDate.createDateObjWithISOString(
            itemData.culture.end_date
          );
          this.endDate = OlabDate.formatYMD(eDate, false);
          this.endTime = OlabDate.formatHM(eDate, false);
          this.endDateISO = this.computeDateISOStr(this.endDate, this.endTime);
        }
        this.computeCultureTime();
      }
    },
    async addItem() {
      // console.log("addItem =", this.item);
      this.item.strain_source_type = this.strainSourceType.key_name;
      this.item.strain_confirmation_method =
        this.strainConfirmationMethod.key_name;
      this.item.culture_stage = this.cultureStage;
      this.item.culture_type = this.cultureType;
      this.item.prod_data_category = this.selectedExprt.prod_data_category;
      this.item.experiment_id = this.selectedExprt.olab_id;
      this.item.media_id = this.media.olab_id;
      this.item.strain_id = this.strain.olab_id;
      // Container(bioreactor, flask, plate, tube, wells)
      this.item.container_type = this.container.olab_type;
      this.item.container_id = this.container.olab_id;
      this.item.container = this.container;
      this.item.incubator_id = this.incubator ? this.incubator.olab_id : null;
      this.item.incubator = this.incubator;
      this.item.image_file = this.imageFile;
      this.item.note = this.note;
      this.item.olab_type = this.selectedType;
      this.item.creator = this.user.email;
      this.item.start_date = this.startDateISO;
      this.item.end_date = this.endDateISO;
      this.item.inoculum_size = this.inoculumSize;
      this.item.initial_OD = this.initialOD;
      this.item.final_OD = this.finalOD;
      this.item.initial_ph_level = this.initialPHLevel;
      this.item.final_ph_level = this.finalPHLevel;
      this.item.initial_volume = this.initialVolume;
      this.item.initial_total_carbon = this.initialTotalCarbon;

      // console.log("(1) item =", this.item);
      await OlabAxios.addCulture(this.item, this.localStatusObj);
      this.$emit("doneCultureAdd");
      // console.log("(2) item =", this.item);
    },
    clearInputs() {
      this.containerType = "flask";
      this.cultureType = "culture";
      this.strainSourceType = null;
      this.strainConfirmationMethod = null;
      this.inoculumType = false;
      this.media = null;
      this.strain = null;
      this.container = null;
      this.incubator = null;
      this.note = null;
      this.imageFile = null;
      this.startDate = null;
      this.startTime = null;
      this.endDate = null;
      this.endTime = null;
      this.cultureTime = 0;
      this.initial_OD = 0;
      this.final_OD = 0;
      this.initialPHLevel = 0;
      this.finalPHLevel = 0;
      this.initialVolume = 0;
      this.initialTotalCarbon = 0;
    },
    cancelAdd() {
      this.resetItem();
      this.$emit("doneCultureAdd");
    },
    resetItem() {
      this.clearInputs();
      this.isLoadingContainers = false;
      this.isLoadingMedias = false;
      this.isLoadingStrains = false;
      this.isLoadingIncubators = false;
      this.item = new OlabItem();
    },
    computeDateISOStr(date, time) {
      let isoString = null;
      if (date && time) {
        isoString = OlabDate.createDateObj(date, time).toISOString();
      }
      return isoString;
    },
    updateStartDate(e) {
      const sDate = e.target.value;
      this.startDate = sDate;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateStartTime(e) {
      const sTime = e.target.value;
      // console.log("updateStarTime: sTime =", sTime);
      this.startTime = sTime;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndDate(e) {
      const eDate = e.target.value;
      // console.log("updateEndDate: eDate =", eDate);
      this.endDate = eDate;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndTime(e) {
      const eTime = e.target.value;
      // console.log("updateEndTime: eTime =", eTime);
      this.endTime = eTime;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateInoculumSize(e) {
      const inoSz = e.target.value;
      // console.log("updateInoculumSize: inoSz =", inoSz);
      this.inoculumSize = inoSz;
    },
    // TODO: 0 - Max, one decimal point
    updateInitialOD(e) {
      const od = e.target.value;
      // console.log("updateInitialOD: od =", od);
      this.initialOD = od;
    },
    // TODO: 0 - Max, one decimal point
    updateFinalOD(e) {
      const od = e.target.value;
      // console.log("updateFinalOD: od =", od);
      this.finalOD = od;
    },
    // TODO: 0 - 14, one decimal point
    updateInitialPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateInitialPHLevel: ph =", ph);
      this.initialPHLevel = ph;
    },
    // TODO: 0 - 14, one decimal point
    updateFinalPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateFinalPHLevel: ph =", ph);
      this.finalPHLevel = ph;
    },
    // TODO: 0 - Max
    updateInitialVolume(e) {
      const vol = e.target.value;
      // console.log("updateInitialVolume: vol =", vol);
      this.initialVolume = vol;
    },
    // TODO: 0 - Max
    updateInitialTotalCarbon(e) {
      const cbg = e.target.value;
      // console.log("updateInitialTotalCarbon: cbg =", cbg);
      this.initialTotalCarbon = cbg;
    }
  },
  watch: {
    fillInCheck() {
      // console.log("fillInCheck =", this.fillInCheck);
      if (this.fillInCheck) {
        this.getCultures("");
      }
    },
    fillInCulture() {
      this.clearInputs();
      if (this.fillInCulture) {
        // console.log("fillInCulture =", this.fillInCulture);
        this.loadCultureData();
      }
    },
    inoculumType() {
      // console.log("inoculumType =", this.inoculumType);
      this.cultureType = this.inoculumType ? "inoculum" : "culture";
    },
    containerType() {
      // Reset and reload
      this.container = null;
      this.getContainers("");
    }
  }
};
</script>
