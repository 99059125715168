class OlabSanity {
  static _processItemField(fieldName) {
    return fieldName == null || fieldName == "" ? "is-invalid" : "is-valid";
  }

  static getValidState(fieldName) {
    return OlabSanity._processItemField(fieldName);
  }

  static isValid(fieldName) {
    return OlabSanity._processItemField(fieldName) == "is-valid";
  }

  static isGreaterThanZero(fieldName) {
    return fieldName > 0 ? "is-valid" : "is-invalid";
  }

  static validateEmail(em) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(em).toLowerCase());
  }

  // Password must be atleast 8 or more characters
  static isValidPW(pwd, cPwd) {
    let result = false;
    // console.log("pwd =", pwd, ", cPwd =", cPwd);
    if (!pwd && !cPwd) {
      // No password change case
      result = true;
    } else if (pwd && cPwd && pwd.length >= 8 && pwd === cPwd) {
      result = true;
    }
    return result;
  }

  static isItemValid(selectedType, item) {
    // console.log("isItemValid =", item);
    let result = false;

    switch (selectedType) {
      case "bioreactor":
        return (
          OlabSanity.isValid(item.name) &&
          item.size > 0 &&
          OlabSanity.isValid(item.unit)
        );
      case "branch":
        return (
          OlabSanity.isValid(item.name) && OlabSanity.isValid(item.start_date)
        );
      // break;
      case "chart":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.project_id) &&
          OlabSanity.isValid(item.source)
        );
      case "culture":
        // console.log("culture check container =", item.container);
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.strain_source_type) &&
          OlabSanity.isValid(item.strain_confirmation_method) &&
          OlabSanity.isValid(item.container) &&
          (item.container.olab_type === "bioreactor" ||
            OlabSanity.isValid(item.incubator)) &&
          OlabSanity.isValid(item.media_id) &&
          OlabSanity.isValid(item.strain_id) &&
          OlabSanity.isValid(item.start_date)
        );
      // break;
      case "flask":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.type) &&
          item.size > 0
        );
      case "incubator":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.type) &&
          OlabSanity.isValid(item.tm)
        );
      case "media":
        return (
          // Note: Name is auto constructed of (recipe_name, media_volume, media_unit)
          // OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.recipe_id) &&
          item.volume > 0 &&
          OlabSanity.isValid(item.purpose)
        );
      case "manufacturer":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.addr1) &&
          OlabSanity.isValid(item.city) &&
          OlabSanity.isValid(item.state) &&
          OlabSanity.isValid(item.zipcode) &&
          OlabSanity.isValid(item.country)
          // OlabSanity.isValid(item.contact_name) &&
          // OlabSanity.validateEmail(item.email) &&
          // OlabSanity.isValid(item.phone)
        );
      case "chemical":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.mfr_id) &&
          OlabSanity.isValid(item.unit)
          // OlabSanity.isValid(item.cat_no) &&
          // OlabSanity.isValid(item.expire_date) &&
          // item.qty > 0 &&
          // OlabSanity.isValid(item.lot_no)
        );
      case "experiment":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.branch_id) &&
          OlabSanity.isValid(item.prod_data_category) &&
          OlabSanity.isValid(item.num_culture_stages) &&
          OlabSanity.isValid(item.start_date) &&
          OlabSanity.isValid(item.control_strain_id) &&
          item.test_strain_ids &&
          OlabSanity.isValid(item.test_strain_ids[0]) &&
          item.hypotheses &&
          item.hypotheses[0] &&
          OlabSanity.isValid(item.hypotheses[0].statement)
        );
      case "recipe":
        return (
          OlabSanity.isValid(item.name) &&
          item.volume > 0 &&
          OlabSanity.isValid(item.unit) &&
          OlabSanity.ingredientsIsValid(item.ingredients)
        );
      case "plate":
        if (
          item.type === "round" &&
          item.dimension &&
          item.dimension.diameter > 0 &&
          item.dimension.height > 0
        ) {
          result = true;
        } else if (
          item.type === "rectangle" &&
          item.dimension &&
          item.dimension.width > 0 &&
          item.dimension.length > 0 &&
          item.dimension.height > 0
        ) {
          result = true;
        }
        // console.log("plate validate =", result);
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.type) &&
          result
        );
      // break;
      case "project":
        return (
          OlabSanity.isValid(item.name) && OlabSanity.isValid(item.start_date)
        );
      // break;
      case "user":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.validateEmail(item.email) &&
          OlabSanity.isValid(item.role) &&
          OlabSanity.isValid(item.division) &&
          OlabSanity.isValidPW(item.passwd, item.confirmed_passwd)
        );
      // break;
      case "strain":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.project_id) &&
          OlabSanity.isValid(item.strain_genotype)
        );
      // break;
      case "tube":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.type) &&
          item.size > 0
        );
      // break;
      case "multiwell":
        return (
          OlabSanity.isValid(item.name) &&
          OlabSanity.isValid(item.dimension) &&
          OlabSanity.isValid(item.dimension.width) &&
          OlabSanity.isValid(item.dimension.length) &&
          OlabSanity.isValid(item.dimension.height) &&
          OlabSanity.isValid(item.dimension.volume_per_well) &&
          OlabSanity.isValid(item.dimension.number_of_wells)
        );
      // break;
    }
    console.log("OlabSanity.isItemValid: No selectedType found!!!");
    return false;
  }

  static MINIMUM_PASSWORD_LENGTH = 8;
  static getValidPWState(pw) {
    let result = "is-invalid";
    if (pw !== null && pw.length >= OlabSanity.MINIMUM_PASSWORD_LENGTH) {
      result = "is-valid";
    }
    return result;
  }

  static getValidCPWState(pw, cpw) {
    let result = "is-invalid";
    if (pw !== null && cpw !== null) {
      if (pw === cpw) {
        result = "is-valid";
      }
    }
    return result;
  }

  static ingredientsIsValid(ingrArr) {
    let ingrArrValid = false;
    if (ingrArr.length > 0) {
      ingrArrValid = true;
    }
    for (let i = 0; i < ingrArr.length; i++) {
      if (
        ingrArr[i].qty <= 0 ||
        ingrArr[i].name === "" ||
        ingrArr[i].unit === "" ||
        ingrArr[i].chem_id === ""
      ) {
        ingrArrValid = false;
        break;
      }
    }
    return ingrArrValid;
  }
}
export { OlabSanity };
