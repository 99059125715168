<template>
  <div v-if="selectedType === 'multiwell'">
    <div class="form-row">
      <div class="form-group col-md-8">
        <span class="mr-1">Name:</span>
        <span class="ml-1">{{ item.name }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-8">
        <span class="mr-1">Manufacturer ID:</span>
        <span class="ml-1">{{ item.mfr_id }}</span>
      </div>
    </div>
    <div v-if="item.dimension">
      <div class="form-row">
        <div class="form-group col-md-4">
          <span class="mr-1">Number of wells:</span>
          <span class="ml-1">{{ item.dimension.number_of_wells }}</span>
        </div>
        <div class="form-group col-md-4">
          <span class="mr-1">Volume per well:</span>
          <span class="ml-1">{{ item.dimension.volume_per_well }}</span>
          <span class="ml-1">mL</span>
        </div>
      </div>
      <div v-if="item.dimension" class="form-row">
        <div class="form-group col-md-4">
          <span class="mr-1">Width:</span>
          <span class="ml-1">{{ item.dimension.width }}</span>
          <span class="ml-1">{{ item.dimension.unit }}</span>
        </div>
        <div class="form-group col-md-4">
          <span class="mr-1">Length:</span>
          <span class="ml-1">{{ item.dimension.length }}</span>
          <span class="ml-1">{{ item.dimension.unit }}</span>
        </div>
        <div class="form-group col-md-4">
          <span class="mr-1">Height:</span>
          <span class="ml-1">{{ item.dimension.height }}</span>
          <span class="ml-1">{{ item.dimension.unit }}</span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <span class="mr-1">Description:</span>
      <span class="ml-1">{{ item.desc }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiwellView",
  props: ["dbInfo", "selectedType", "item"],
  computed: {
    capitalizeType() {
      const word = this.item && this.item.type ? this.item.type : "";

      return word.length > 1
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : "";
    }
  }
};
</script>
