import axios from "axios";
import { OlabUtils } from "./olabutils.js";
import { OlabItem } from "./olabitem.js";

class OlabAxios {
  // 20 counts per page
  static LIMIT_PER_PAGE = 20;
  static MAX_LIMIT = 2000;

  constructor() {
    // console.log("OlabAxios.constructor() ...");
  }

  static addItemToItems(type, item, items) {
    // console.log("addItemToItems: type =", type);
    // console.log("item =", item);
    // console.log("item.hypotheses =", item.hypotheses);

    // Add item to the begining of items
    items.unshift(Object.assign(new OlabItem(), item));
  }

  static updateItems(item, items, dFlag) {
    // Update item in array
    for (let i in items) {
      if (items[i].olab_id === item.olab_id) {
        // console.log("Found items[", i, "].olab_id = ", items[i].olab_id);
        if (dFlag === true) {
          // Delete element i from items
          items.splice(i, 1);
        } else {
          items[i].set(item);
        }
        break;
      }
    }
  }

  static setStatusObjProgress(progress, statusObj) {
    statusObj.progress = progress;
    if (progress) {
      statusObj.message = null;
      statusObj.errorMessage = null;
    }
  }

  static setStatusObjSearch(searching, statusObj) {
    statusObj.searching = searching;
    if (searching) {
      statusObj.message = null;
      statusObj.errorMessage = null;
    }
  }

  static async saveItem(user, item, items, statusObj) {
    // console.log("OlabAxios.saveItem (item = ", item, ", items = ", items);

    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);

    switch (item.olab_type) {
      case "bioreactor":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/bioreactors/${item.olab_id}`,
            data: {
              name: item.name,
              size: item.size,
              unit: item.unit,
              mfr_id: item.mfr_id,
              air_vvm: item.air_vvm,
              rpm: item.rpm,
              tm: item.tm,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.bioreactor);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.bioreactor.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "branch":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/branchs/${item.olab_id}`,
            data: {
              name: item.name,
              project_id: item.project_id,
              members: item.members,
              desc: item.desc,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.branch);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.branch.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "chart":
        console.log("item =", item);
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/charts/${item.olab_id}`,
            data: {
              name: item.name,
              // READ ONLY properties, set once at creation
              // source: item.source,
              control: item.control,
              svg_filenames: item.svg_filenames,
              auto_range: item.auto_range,
              // READ ONLY properties for internal use only
              // left_y_axes_limits: item.left_y_axes_limits,
              // right_y_axes_limits: item.right_y_axes_limits,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.chart);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.chart.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "culture":
        // console.log("TODO: XXXX Implement save culture: imageFile");
        // console.log("item =", item);
        // console.log("incubator =", item.incubator);
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/cultures/${item.olab_id}`,
            data: {
              name: item.name,
              strain_source_type: item.strain_source_type,
              strain_confirmation_method: item.strain_confirmation_method,
              culture_type: item.culture_type,
              // TODO: Need to add this setting if there is more than one CSV format in the future
              // prod_data_category: item.prod_data_category,
              container_id: item.container_id,
              container_type: item.container_type,
              container: item.container,
              incubator_id: item.incubator_id,
              incubator_tm: item.incubator ? item.incubator.tm : null,
              incubator_rpm: item.incubator ? item.incubator.rpm : null,
              media_id: item.media_id,
              strain_id: item.strain_id,
              start_date: item.start_date,
              end_date: item.end_date,
              initial_OD: item.initial_OD ? item.initial_OD : 0,
              final_OD: item.final_OD ? item.final_OD : 0,
              initial_ph_level: item.initial_ph_level
                ? item.initial_ph_level
                : 0,
              final_ph_level: item.final_ph_level ? item.final_ph_level : 0,
              initial_total_carbon: item.initial_total_carbon
                ? item.initial_total_carbon
                : 0,
              initial_volume: item.initial_volume ? item.initial_volume : 0,
              data_uploaded: item.data_uploaded ? item.data_uploaded : false,
              inoculum_size: item.inoculum_size ? item.inoculum_size : 0,
              note: item.note
            }
          });
          // console.log(res.data.data.culture);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.incubator = res.data.data.culture.incubator;
            item.container = res.data.data.culture.container;
            item.updatedAt = res.data.data.culture.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "experiment":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/experiments/${item.olab_id}`,
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              branch_id: item.branch_id,
              hypotheses: item.hypotheses,
              control_strain_id: item.control_strain_id,
              test_strain_ids: item.test_strain_ids,
              related_expr_ids: item.related_expr_ids,
              num_culture_stages: item.num_culture_stages,
              verifier_id: item.verifier_id,
              verifier_signature: item.verifier_signature,
              verified_date: item.verified_date,
              desc: item.desc,
              note: item.note,
              start_date: item.start_date,
              end_date: item.end_date,
              updated_by: user.email
            }
          });
          // console.log(res.data.data.experiment);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.experiment.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "flask":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/flasks/${item.olab_id}`,
            data: {
              name: item.name,
              mfr_id: item.mfr_id,
              type: item.type,
              size: item.size,
              unit: "ml",
              // unit: item.unit,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.flask);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.flask.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "incubator":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/incubators/${item.olab_id}`,
            data: {
              name: item.name,
              mfr_id: item.mfr_id,
              type: item.type,
              tm: item.tm,
              rpm: item.rpm,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.incubator);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.incubator.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "manufacturer":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/manufacturers/${item.olab_id}`,
            data: {
              name: item.name,
              creator: item.creator,
              addr1: item.addr1,
              addr2: item.addr2,
              city: item.city,
              state: item.state,
              zipcode: item.zipcode,
              country: item.country,
              contact_name: item.contact_name,
              phone: item.phone,
              email: item.email,
              note: item.note
            }
          });
          // console.log(res.data.data.manufacturer);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.manufacturer.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "media":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/medias/${item.olab_id}`,
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              recipe_id: item.recipe_id,
              volume: item.volume,
              unit: item.unit,
              purpose: item.purpose,
              expire_date: item.expire_date,
              note: item.note
            }
          });
          // console.log(res.data.data.media);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.media.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "chemical":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/chemicals/${item.olab_id}`,
            data: {
              name: item.name,
              creator: item.creator,
              cat_no: item.cat_no,
              expire_date: item.expire_date,
              lot_no: item.lot_no,
              mfr_id: item.mfr_id,
              qty: item.qty,
              unit: item.unit,
              note: item.note
            }
          });
          // console.log(res.data.data.chemical);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.chemical.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "recipe":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/recipes/${item.olab_id}`,
            data: {
              name: item.name,
              creator: item.creator,
              volume: item.volume,
              unit: item.unit,
              ingredients: item.ingredients
            }
          });
          // console.log(res.data.data.recipe);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.recipe.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "plate":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/plates/${item.olab_id}`,
            data: {
              name: item.name,
              mfr_id: item.mfr_id,
              type: item.type,
              dimension: item.dimension,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.plate);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.plate.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "project":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/projects/${item.olab_id}`,
            data: {
              name: item.name,
              ra_locks: item.ra_locks,
              desc: item.desc,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.project);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.project.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "user":
        try {
          const formData = new FormData();
          formData.append("name", item.name);
          formData.append("email", item.email);

          if (item.passwd && item.confirmed_passwd) {
            formData.append("password", item.passwd);
            formData.append("passwordConfirm", item.confirmed_passwd);
          }

          if (item.photo) {
            // olab_id is required for updating photo
            formData.append("olab_id", item.olab_id);
            formData.append("photo", item.photo);
          }
          const patchObj = {
            method: "PATCH",
            data: formData
          };

          if (user.role === "admin") {
            // Add role update if it is from admin
            formData.append("role", item.role);
            formData.append("division", item.division);
            patchObj.url = `/api/v1/users/${item.email}`;
          } else {
            patchObj.url = "/api/v1/users/updateMe";
          }
          const res = await axios(patchObj);
          // console.log(res.data.data.user);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // Clear passwd and confirmed_passwd
            item.passwd = null;
            item.confirmed_passwd = null;
            item.updatedAt = res.data.data.user.updatedAt;
            OlabAxios.updateItems(item, items, false);
            // Update current user data if user has changed.
            if (user.email === item.email) {
              user.name = item.name;
              user.role = item.role;
            }
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "strain":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/strains/${item.olab_id}`,
            data: {
              name: item.name,
              project_id: item.project_id,
              strain_genotype: item.strain_genotype,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.strain);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.strain.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "tube":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/tubes/${item.olab_id}`,
            data: {
              name: item.name,
              mfr_id: item.mfr_id,
              type: item.type,
              size: item.size,
              unit: "ml",
              // unit: item.unit,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.tube);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.tube.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "multiwell":
        try {
          const res = await axios({
            method: "PATCH",
            url: `/api/v1/multiwells/${item.olab_id}`,
            data: {
              name: item.name,
              mfr_id: item.mfr_id,
              type: item.type,
              dimension: item.dimension,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.multiwell);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            item.updatedAt = res.data.data.multiwell.updatedAt;
            OlabAxios.updateItems(item, items, false);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      default:
        console.log("OlabAxios.saveItem: unknown type = " + item.olab_type);
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  static async getUserProjects(user, statusObj) {
    // console.log("getUserProjects: user = ", user);

    let projs = null;
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);

    // For debugging: Simulate delay ...
    // await new Promise((r) => setTimeout(r, 100));

    if (user && user.email) {
      try {
        const res = await axios({
          method: "GET",
          url: `/api/v1/users/getProjects/${user.email}`
        });
        // console.log(res.data.data.projects);
        if (
          res.data.status === "success" &&
          res.data.data &&
          res.data.data.projects
        ) {
          // console.log("Document successfully written!");
          projs = res.data.data.projects;
          console.log("Return data: ", res.data.data.projects);
        } else {
          console.log("production upload is null");
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
    return projs;
  }

  static async loadProductionUpload(user, culture, statusObj) {
    // console.log("loadProductionUpload: culture = ", culture);
    // console.log("user =", user);

    let uplds = null;
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);

    // For debugging: Simulate delay ...
    // await new Promise((r) => setTimeout(r, 100));

    if (culture && culture.olab_id) {
      try {
        const res = await axios({
          method: "GET",
          url: `/api/v1/productionuploads/${culture.olab_id}`
        });

        // console.log(res.data.data);
        if (res.data.status === "success" && res.data.data) {
          // console.log("Document successfully written!");
          uplds = res.data.data;
          // console.log("*** Return uplds: ", uplds);
          // const prodUpld = uplds.productionUpload;
          // const fillinsUpld = uplds.fillinsUpload;
          // const machinesUpld = uplds.machinesUpload;
          // const measurementsUpld = uplds.measurementsUpload;
          // console.log("Return prodUpld: ", prodUpld);
          // console.log("Return fillinsUpld: ", fillinsUpld);
          // console.log("Return machinesUpld: ", machinesUpld);
          // console.log("Return measurementsUpld: ", measurementsUpld);
        } else {
          console.log("production upload is null");
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
    return uplds;
  }

  // A separate of saveItem for upload of culture's production data
  static async updateCultureProductionData(user, culture, category, statusObj) {
    console.log("updateCultureProductionData: culture = ", culture);
    console.log("user =", user);
    console.log("category =", category);

    let result = null;
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && culture && culture.csv_file) {
      try {
        const formData = new FormData();
        formData.append("creator", user.email);
        formData.append("force_upload", false);
        formData.append("category", category);
        formData.append("csv_file", culture.csv_file);
        const res = await axios({
          method: "POST",
          url: `/api/v1/cultures/uploadProductionData/${culture.olab_id}`,
          data: formData
        });
        // console.log(res.data.data.production);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          result = res.data.data.production;
          culture.updatedAt = result.updatedAt;
          // console.log("Return data: ", res.data.data.production);
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
    return result;
  }

  // A separate of saveItem for Culture to update culture's workflow data
  static async updateCultureData(user, culture, statusObj) {
    // console.log("updateCultureData: culture = ", culture, ", user =", user);
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && culture) {
      try {
        const res = await axios({
          method: "PATCH",
          url: `/api/v1/cultures/updateCultureData/${culture.olab_id}`,
          data: {
            start_date: culture.start_date,
            end_date: culture.end_date,
            initial_OD: culture.initial_OD ? culture.initial_OD : 0,
            final_OD: culture.final_OD ? culture.final_OD : 0,
            initial_ph_level: culture.initial_ph_level
              ? culture.initial_ph_level
              : 0,
            final_ph_level: culture.final_ph_level ? culture.final_ph_level : 0,
            initial_total_carbon: culture.initial_total_carbon
              ? culture.initial_total_carbon
              : 0,
            initial_volume: culture.initial_volume ? culture.initial_volume : 0,
            data_uploaded: culture.data_uploaded
              ? culture.data_uploaded
              : false,
            inoculum_size: culture.inoculum_size ? culture.inoculum_size : 0,
            note: culture.note,
            updated_by: user.email
          }
        });
        // console.log(res.data.data.experiment);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          culture.updatedAt = res.data.data.culture.updatedAt;
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  // A separate of saveItem for Experiment to update experiment's workflow data
  static async updateExperiment(user, expr, statusObj) {
    // console.log("OlabAxios.updateExperiment (expr = ", expr, ", user =", user);
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && expr) {
      try {
        const res = await axios({
          method: "PATCH",
          url: `/api/v1/experiments/${expr.olab_id}`,
          data: {
            verifier_id: expr.verifier_id,
            verifier_signature: expr.verifier_signature,
            verified_date: expr.verified_date,
            start_date: expr.start_date,
            end_date: expr.end_date,
            updated_by: user.email
          }
        });
        // console.log(res.data.data.experiment);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          expr.updatedAt = res.data.data.experiment.updatedAt;
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  static async verifyExperiment(user, expr, statusObj) {
    // console.log("OlabAxios.verifyExperiment (expr = ", expr, ", user =", user);
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && expr) {
      try {
        const res = await axios({
          method: "PATCH",
          url: `/api/v1/experiments/verifyExperiment/${expr.olab_id}`,
          data: {
            verifier_id: expr.verifier_id,
            verifier_signature: expr.verifier_signature,
            verified_date: expr.verified_date
          }
        });
        // console.log(res.data.data.experiment);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          expr.updatedAt = res.data.data.experiment.updatedAt;
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  // Add Culture is a special case
  static async addCulture(item, statusObj) {
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    try {
      const res = await axios({
        method: "POST",
        url: "/api/v1/cultures",
        data: {
          olab_type: item.olab_type,
          strain_source_type: item.strain_source_type,
          strain_confirmation_method: item.strain_confirmation_method,
          culture_stage: item.culture_stage,
          culture_type: item.culture_type,
          prod_data_category: item.prod_data_category,
          container_type: item.container_type,
          container_id: item.container_id,
          container: item.container,
          experiment_id: item.experiment_id,
          media_id: item.media_id,
          strain_id: item.strain_id,
          incubator_id: item.incubator_id,
          incubator_tm: item.incubator ? item.incubator.tm : null,
          incubator_rpm: item.incubator ? item.incubator.rpm : null,
          image_file: item.image_file,
          creator: item.creator,
          start_date: item.start_date,
          end_date: item.end_date,
          initial_OD: item.initial_OD ? item.initial_OD : 0,
          final_OD: item.final_OD ? item.final_OD : 0,
          initial_ph_level: item.initial_ph_level ? item.initial_ph_level : 0,
          final_ph_level: item.final_ph_level ? item.final_ph_level : 0,
          initial_total_carbon: item.initial_total_carbon
            ? item.initial_total_carbon
            : 0,
          initial_volume: item.initial_volume ? item.initial_volume : 0,
          data_uploaded: item.data_uploaded ? item.data_uploaded : false,
          inoculum_size: item.inoculum_size ? item.inoculum_size : 0,
          note: item.note
        }
      });
      // console.log(res.data.data.culture);
      if (res.data.status === "success") {
        // console.log("Document successfully written!");
        // copy item to a new object and add that to the computed list
        item.olab_id = res.data.data.culture.olab_id;
      }
    } catch (err) {
      // console.error("Error writing document: ", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.progress = false;

    return {
      status: "success",
      message: null
    };
  }

  static async addItem(item, type, items, statusObj) {
    // console.log("OlabAxios.addItem: item =", item);
    // console.log("type =", type, ", items=", items);

    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);

    // Set additional fields
    item.olab_type = type;

    switch (type) {
      case "bioreactor":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/bioreactors",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              size: item.size,
              unit: item.unit,
              mfr_id: item.mfr_id,
              air_vvm: item.air_vvm,
              rpm: item.rpm,
              tm: item.tm,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.bioreactor);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.bioreactor.olab_id;
            item.updatedAt = res.data.data.bioreactor.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "branch":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/branchs",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              project_id: item.project_id,
              members: item.members,
              creator: item.creator,
              desc: item.desc,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.branch);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.branch.olab_id;
            item.updatedAt = res.data.data.branch.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "chart":
        console.log("Add Chart: item =", item);
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/charts",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              source: item.source,
              project_id: item.project_id,
              creator: item.creator,
              control: item.control,
              svg_filenames: item.svg_filenames,
              auto_range: item.auto_range,
              // READ ONLY Properties for internal use only
              // left_y_axes_limits: item.left_y_axes_limits,
              // right_y_axes_limits: item.right_y_axes_limits,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.chart);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy data to item's fields and add that to the computed list
            item.olab_id = res.data.data.chart.olab_id;
            item.updatedAt = res.data.data.chart.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "experiment":
        // console.log("Add Experiment: item =", item);
        // console.log("Add Experiment: item.hypotheses[0] =", item.hypotheses[0]);
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/experiments",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              branch_id: item.branch_id,
              hypotheses: item.hypotheses,
              control_strain_id: item.control_strain_id,
              test_strain_ids: item.test_strain_ids,
              related_expr_ids: item.related_expr_ids,
              num_culture_stages: item.num_culture_stages,
              verifier_id: item.verifier_id,
              verifier_signature: item.verifier_signature,
              verified_date: item.verified_date,
              desc: item.desc,
              note: item.note,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.experiment);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy data to item's fields and add that to the computed list
            item.olab_id = res.data.data.experiment.olab_id;
            item.updatedAt = res.data.data.experiment.updatedAt;
            // Note: This reassigning is to fix item's hypotheses got reset
            //       somewhere. TODO: FIX THIS.
            item.hypotheses = res.data.data.experiment.hypotheses;
            // console.log("** item.hypotheses[0] =", item.hypotheses[0]);
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "flask":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/flasks",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              mfr_id: item.mfr_id,
              creator: item.creator,
              type: item.type,
              size: item.size,
              unit: "ml",
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.flask);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.flask.olab_id;
            item.updatedAt = res.data.data.flask.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "incubator":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/incubators",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              mfr_id: item.mfr_id,
              creator: item.creator,
              type: item.type,
              tm: item.tm,
              rpm: item.rpm,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.incubator);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy data to item's fields and add that to the computed list
            item.olab_id = res.data.data.incubator.olab_id;
            item.updatedAt = res.data.data.incubator.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "manufacturer":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/manufacturers",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              addr1: item.addr1,
              addr2: item.addr2,
              city: item.city,
              state: item.state,
              zipcode: item.zipcode,
              country: item.country,
              contact_name: item.contact_name,
              phone: item.phone,
              email: item.email,
              note: item.note
            }
          });
          // console.log(res.data.data.manufacturer);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.manufacturer.olab_id;
            item.updatedAt = res.data.data.manufacturer.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "media":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/medias",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              recipe_id: item.recipe_id,
              volume: item.volume,
              unit: item.unit,
              purpose: item.purpose,
              expire_date: item.expire_date,
              note: item.note
            }
          });
          // console.log(res.data.data.media);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.media.olab_id;
            item.updatedAt = res.data.data.media.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "chemical":
        try {
          console.log("print out item =", item);
          const res = await axios({
            method: "POST",
            url: "/api/v1/chemicals",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              cat_no: item.cat_no,
              expire_date: item.expire_date,
              lot_no: item.lot_no,
              mfr_id: item.mfr_id,
              qty: item.qty,
              unit: item.unit,
              note: item.note
            }
          });
          // console.log(res.data.data.chemical);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.chemical.olab_id;
            item.updatedAt = res.data.data.chemical.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "recipe":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/recipes",
            data: {
              name: item.name,
              creator: item.creator,
              volume: item.volume,
              unit: item.unit,
              ingredients: item.ingredients
            }
          });
          // console.log(res.data.data.recipe);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.recipe.olab_id;
            item.updatedAt = res.data.data.recipe.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "plate":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/plates",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              mfr_id: item.mfr_id,
              creator: item.creator,
              type: item.type,
              dimension: item.dimension,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.plate);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.plate.olab_id;
            item.updatedAt = res.data.data.plate.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "project":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/projects",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              ra_locks: item.ra_locks,
              desc: item.desc,
              start_date: item.start_date,
              end_date: item.end_date
            }
          });
          // console.log(res.data.data.project);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.project.olab_id;
            item.division = res.data.data.project.division;
            item.updatedAt = res.data.data.project.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "user":
        console.log("item.olab_id = " + item.olab_id);
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/users",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              division: item.division,
              email: item.email,
              password: item.passwd,
              passwordConfirm: item.confirmed_passwd,
              role: item.role,
              user_id: item.user_id
            }
          });
          // console.log(res.data.data.user);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // Clear passwd and confirmed_passwd
            item.passwd = null;
            item.confirmed_passwd = null;
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.user.olab_id;
            item.updatedAt = res.data.data.user.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "strain":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/strains",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              creator: item.creator,
              project_id: item.project_id,
              strain_genotype: item.strain_genotype,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.strain);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.strain.olab_id;
            item.updatedAt = res.data.data.strain.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "tube":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/tubes",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              mfr_id: item.mfr_id,
              creator: item.creator,
              type: item.type,
              size: item.size,
              unit: "ml",
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.tube);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.tube.olab_id;
            item.updatedAt = res.data.data.tube.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      case "multiwell":
        try {
          const res = await axios({
            method: "POST",
            url: "/api/v1/multiwells",
            data: {
              olab_type: item.olab_type,
              name: item.name,
              mfr_id: item.mfr_id,
              creator: item.creator,
              dimension: item.dimension,
              desc: item.desc,
              note: item.note
            }
          });
          // console.log(res.data.data.multiwell);
          if (res.data.status === "success") {
            // console.log("Document successfully written!");
            // copy item to a new object and add that to the computed list
            item.olab_id = res.data.data.multiwell.olab_id;
            item.updatedAt = res.data.data.multiwell.updatedAt;
            this.addItemToItems(type, item, items);
          }
        } catch (err) {
          // console.error("Error writing document: ", err);
          statusObj.errorMessage = OlabUtils.getErrorMessage(err);
        }
        break;
      default:
        console.log("OlabAxios.addItem: unknown type = " + type);
    }
    // Done! Reset progress status
    statusObj.progress = false;

    return {
      status: "success",
      message: null
    };
  }

  static async getExperimentData(item, statusObj) {
    // console.log("getExperimentData: item =", item);
    let exprt = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/experiments/getExperimentData/${item.olab_id}`
      });

      // console.log("getExperimentData: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.experiment
      ) {
        exprt = {
          experiment: res.data.data.experiment,
          branch: res.data.data.branch,
          project: res.data.data.project,
          control_strain: res.data.data.control_strain,
          test_strains: res.data.data.test_strains,
          related_exprts: res.data.data.related_exprts,
          cultures: res.data.data.cultures,
          maxCultureStageCreated: res.data.data.maxCultureStageCreated,
          verifier: res.data.data.verifier
        };
        // console.log("experiment =", exprt);
      } else {
        console.log("experiment is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getExperimentData - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return exprt;
  }

  static async getCultureData(item, statusObj) {
    // console.log("getCultureData: item =", item);
    let cultureData = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/cultures/getCultureData/${item.olab_id}`
      });

      // console.log("getCultureData: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.culture
      ) {
        cultureData = {
          culture: res.data.data.culture,
          experiment: res.data.data.experiment,
          media: res.data.data.media,
          strain: res.data.data.strain
        };
        // console.log("cultureData =", cultureData);
      } else {
        console.log("culture is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCultureData - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return cultureData;
  }

  static async getProject(olabID, statusObj) {
    // console.log("getProject: olabID =", olabID);
    let proj = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/projects/${olabID}`
      });

      // console.log("getProject: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.project
      ) {
        proj = res.data.data.project;
        // console.log("project =", proj);
      } else {
        console.log("project is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getProject - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return proj;
  }

  static async getRecipe(olabID, statusObj) {
    // console.log("getRecipe: olabID =", olabID);
    let rcp = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/recipes/${olabID}`
      });

      // console.log("getRecipe: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.recipe
      ) {
        rcp = res.data.data.recipe;
        // console.log("recipe =", rcp);
      } else {
        console.log("recipe is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getRecipe - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return rcp;
  }

  static async getBioreactors(searchBody, statusObj) {
    // console.log("getBioreactors' body =", searchBody);
    const bioreactors = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load bioreactors
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/bioreactors/mergeFind",
        data: {
          search_type: "bioreactor",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("bioreactors: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.bioreactors
      ) {
        // console.log("bioreactors: results = " + res.data.results);
        const bios = res.data.data.bioreactors;
        // console.log("bioreactors: bios =", bios);
        bios.forEach((bio) => {
          bioreactors.push(new OlabItem(bio));
        });
      } else {
        console.log("bioreactors is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getBioreactors - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return bioreactors;
  }

  static async getBranchs(searchBody, statusObj) {
    // console.log("getBranchs' body =", searchBody);
    const branchs = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // For debugging: Simulate delay ...
    // await new Promise((r) => setTimeout(r, 100));

    // Load branchs
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/branchs/mergeFind",
        data: {
          search_type: "branch",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("branchs: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.branchs
      ) {
        // console.log("branchs: results = " + res.data.results);
        const brs = res.data.data.branchs;
        // console.log("branchs: brs =", brs);
        brs.forEach((br) => {
          if (br.start_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            br.start_date = br.start_date.substring(0, 10);
          }
          if (br.end_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            br.end_date = br.end_date.substring(0, 10);
          }
          branchs.push(new OlabItem(br));
        });
      } else {
        console.log("branchs is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getBranchs - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return branchs;
  }

  static async getCultures(searchBody, statusObj) {
    // console.log("getCultures' body =", searchBody);
    const cultures = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load cultures
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/cultures/mergeFind",
        data: {
          search_type: "culture",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: searchBody.auxID ? searchBody.auxID : null,
          aux_id2: searchBody.auxID2 ? searchBody.auxID2 : null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("cultures: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.cultures
      ) {
        // console.log("cultures: results = " + res.data.results);
        const clts = res.data.data.cultures;
        // console.log("cultures: clts =", clts);
        clts.forEach((clt) => {
          // Do not filter out the time in start_date and end_date
          cultures.push(new OlabItem(clt));
        });
      } else {
        console.log("cultures is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCultures - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    // console.log("cultures =", cultures);
    return cultures;
  }

  static async getExperiments(searchBody, statusObj) {
    // console.log("getExperiments' body =", searchBody);
    const experiments = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load experiments
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/experiments/mergeFind",
        data: {
          search_type: "experiment",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("experiments: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.experiments
      ) {
        // console.log("experiments: results = " + res.data.results);
        const exprts = res.data.data.experiments;
        // console.log("experiments: exprts =", exprts);
        exprts.forEach((exprt) => {
          if (exprt.start_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            exprt.start_date = exprt.start_date.substring(0, 10);
          }
          if (exprt.end_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            exprt.end_date = exprt.end_date.substring(0, 10);
          }
          if (exprt.verified_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            exprt.verified_date = exprt.verified_date.substring(0, 10);
          }
          experiments.push(new OlabItem(exprt));
        });
      } else {
        console.log("experiments is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getExperiments - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return experiments;
  }

  static async getFlasks(searchBody, statusObj) {
    // console.log("getFlasks' body =", searchBody);
    const flasks = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load flasks
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/flasks/mergeFind",
        data: {
          search_type: "flask",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("flasks: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.flasks
      ) {
        // console.log("flasks: results = " + res.data.results);
        const flks = res.data.data.flasks;
        // console.log("flasks: flks =", flks);
        flks.forEach((flk) => {
          flasks.push(new OlabItem(flk));
        });
      } else {
        console.log("flasks is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getFlasks - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return flasks;
  }

  static async getPlates(searchBody, statusObj) {
    // console.log("getPlates' body =", searchBody);
    const plates = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load plates
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/plates/mergeFind",
        data: {
          search_type: "plate",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("plates: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.plates
      ) {
        // console.log("plates: results = " + res.data.results);
        const plts = res.data.data.plates;
        // console.log("plates: plts =", plts);
        plts.forEach((plt) => {
          plates.push(new OlabItem(plt));
        });
      } else {
        console.log("plates is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getPlates - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return plates;
  }

  static async getMultiwells(searchBody, statusObj) {
    // console.log("getMultiwells' body =", searchBody);
    const multiwells = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load multiwells
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/multiwells/mergeFind",
        data: {
          search_type: "multiwell",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("multiwells: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.multiwells
      ) {
        // console.log("multiwells: results = " + res.data.results);
        const plts = res.data.data.multiwells;
        // console.log("multiwells: plts =", plts);
        plts.forEach((plt) => {
          multiwells.push(new OlabItem(plt));
        });
      } else {
        console.log("multiwells is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getMultiwells - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return multiwells;
  }

  static async getProjects(searchBody, statusObj) {
    // console.log("getProjects' body =", searchBody);
    const projects = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // For debugging: Simulate delay ...
    // await new Promise((r) => setTimeout(r, 100));

    // Load projects
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/projects/mergeFind",
        data: {
          search_type: "project",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("projects: status = ", res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.projects
      ) {
        // console.log("projects: results = " + res.data.results);
        const projs = res.data.data.projects;
        // console.log("projects: projs =", projs);
        projs.forEach((proj) => {
          if (proj.start_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            proj.start_date = proj.start_date.substring(0, 10);
          }
          if (proj.end_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            proj.end_date = proj.end_date.substring(0, 10);
          }
          projects.push(new OlabItem(proj));
        });
      } else {
        console.log("projects is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getProjects - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return projects;
  }

  static async getUsers(searchBody, statusObj) {
    // console.log("getUsers' body =", searchBody);
    const users = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load users
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/users/mergeFind",
        data: {
          search_type: "user",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("users: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.users
      ) {
        // console.log("users: results = " + res.data.results);
        const usrs = res.data.data.users;
        // console.log("users: usrs =", usrs);
        usrs.forEach((usr) => {
          users.push(new OlabItem(usr));
        });
      } else {
        console.log("users is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getUsers - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return users;
  }

  // Note: This is a modified Strains (simpler strain data)
  static async getStrains(searchBody, statusObj) {
    // console.log("getStrains' body =", searchBody);
    const strains = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load strains
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/strains/mergeFind",
        data: {
          search_type: "strain",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("strains: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.strains
      ) {
        // console.log("strains: results = " + res.data.results);
        const strns = res.data.data.strains;
        // console.log("strains: strns =", strns);
        strns.forEach((strn) => {
          strains.push(new OlabItem(strn));
        });
      } else {
        console.log("strains is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getStrains - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return strains;
  }

  static async getTubes(searchBody, statusObj) {
    // console.log("getTubes' body =", searchBody);
    const tubes = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load tubes
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/tubes/mergeFind",
        data: {
          search_type: "tube",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("tubes: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.tubes
      ) {
        // console.log("tubes: results = " + res.data.results);
        const tbs = res.data.data.tubes;
        // console.log("tubes: tbs =", tbs);
        tbs.forEach((tb) => {
          tubes.push(new OlabItem(tb));
        });
      } else {
        console.log("tubes is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getTubes - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return tubes;
  }

  static async getIncubators(searchBody, statusObj) {
    // console.log("getIncubators' body =", searchBody);
    const incubators = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load incubators
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/incubators/mergeFind",
        data: {
          search_type: "incubator",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("incubators: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.incubators
      ) {
        // console.log("incubators: results = " + res.data.results);
        const ibrs = res.data.data.incubators;
        // console.log("incubators: ibrs =", ibrs);
        ibrs.forEach((ibr) => {
          incubators.push(new OlabItem(ibr));
        });
      } else {
        console.log("incubators is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getIncubators - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return incubators;
  }

  static async getManufacturers(searchBody, statusObj) {
    // console.log("getManufacturers' body =", searchBody);
    const manufacturers = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load manufacturers
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/manufacturers/mergeFind",
        data: {
          search_type: "manufacturer",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("manufacturers: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.manufacturers
      ) {
        // console.log("manufacturers: results = " + res.data.results);
        const mfrs = res.data.data.manufacturers;
        // console.log("manufacturers: mfrs =", mfrs);
        mfrs.forEach((mfr) => {
          manufacturers.push(new OlabItem(mfr));
        });
      } else {
        console.log("manufacturers is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getManufacturers - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return manufacturers;
  }

  static async getMedias(searchBody, statusObj) {
    // console.log("getMedias' body =", searchBody);
    const medias = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load medias
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/medias/mergeFind",
        data: {
          search_type: "media",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("medias: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.medias
      ) {
        // console.log("medias: results = " + res.data.results);
        const mdas = res.data.data.medias;
        // console.log("medias: mdas =", mdas);
        mdas.forEach((mda) => {
          if (mda.expire_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            mda.expire_date = mda.expire_date.substring(0, 10);
          }
          medias.push(new OlabItem(mda));
        });
      } else {
        console.log("medias is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getMedias - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return medias;
  }

  static async getChemicals(searchBody, statusObj) {
    // console.log("getChemicals' body =", searchBody);
    const chemicals = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load chemicals
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/chemicals/mergeFind",
        data: {
          search_type: "chemical",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("chemicals: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.chemicals
      ) {
        // console.log("chemicals: results = " + res.data.results);
        const chms = res.data.data.chemicals;
        // console.log("chemicals: chms =", chms);
        chms.forEach((chm) => {
          if (chm.expire_date) {
            // datepicker ONLY accept YYYY-MM-DD part of string
            chm.expire_date = chm.expire_date.substring(0, 10);
          }
          chemicals.push(new OlabItem(chm));
        });
      } else {
        console.log("chemicals is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getChemicals - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return chemicals;
  }

  static async getRecipes(searchBody, statusObj) {
    // console.log("getRecipes' body =", searchBody);
    const recipes = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load recipes
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/recipes/mergeFind",
        data: {
          search_type: "recipe",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("recipes: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.recipes
      ) {
        // console.log("recipes: results = " + res.data.results);
        const rcps = res.data.data.recipes;
        // console.log("recipes: rcps =", rcps);
        rcps.forEach((rcp) => {
          recipes.push(new OlabItem(rcp));
        });
      } else {
        console.log("recipes is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getRecipes - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return recipes;
  }

  static async getItems(sType, searchBody, statusObj) {
    let items = [];
    try {
      switch (sType) {
        case "bioreactor":
          items = await OlabAxios.getBioreactors(searchBody, statusObj);
          break;
        case "branch":
          items = await OlabAxios.getBranchs(searchBody, statusObj);
          break;
        case "chart":
          items = await OlabAxios.getCharts(searchBody, statusObj);
          break;
        case "chemical":
          items = await OlabAxios.getChemicals(searchBody, statusObj);
          break;
        case "culture":
          items = await OlabAxios.getCultures(searchBody, statusObj);
          break;
        case "experiment":
          items = await OlabAxios.getExperiments(searchBody, statusObj);
          break;
        case "flask":
          items = await OlabAxios.getFlasks(searchBody, statusObj);
          break;
        case "incubator":
          items = await OlabAxios.getIncubators(searchBody, statusObj);
          break;
        case "manufacturer":
          items = await OlabAxios.getManufacturers(searchBody, statusObj);
          break;
        case "media":
          items = await OlabAxios.getMedias(searchBody, statusObj);
          break;
        case "multiwell":
          items = await OlabAxios.getMultiwells(searchBody, statusObj);
          break;
        case "plate":
          items = await OlabAxios.getPlates(searchBody, statusObj);
          break;
        case "project":
          items = await OlabAxios.getProjects(searchBody, statusObj);
          break;
        case "recipe":
          items = await OlabAxios.getRecipes(searchBody, statusObj);
          break;
        case "strain":
          items = await OlabAxios.getStrains(searchBody, statusObj);
          break;
        case "tube":
          items = await OlabAxios.getTubes(searchBody, statusObj);
          break;
        case "user":
          items = await OlabAxios.getUsers(searchBody, statusObj);
          break;
        default:
          OlabUtils.errorLog("getItems: Unknown sType = " + sType);
      }
    } catch (err) {
      // OlabUtils.errorLog("** getItems - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    return items;
  }

  static async getBaseunits(searchBody, statusObj) {
    console.log("getBaseunits' body =", searchBody);
    const baseunits = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load baseunits
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "key_name";
      const sortBy = searchField === "merge" ? "key_name" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/baseunits/mergeFind",
        data: {
          search_type: "baseunit",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      console.log("baseunits: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.baseunits
      ) {
        // console.log("baseunits: results = " + res.data.results);
        const bus = res.data.data.baseunits;
        // console.log("baseunits: bus =", bus);
        bus.forEach((bu) => {
          baseunits.push(new OlabItem(bu));
        });
      } else {
        console.log("baseunits is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getBaseunits - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return baseunits;
  }

  static async getCharts(searchBody, statusObj) {
    // console.log("getCharts' body =", searchBody);
    const charts = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load charts
    try {
      // Compute sortBy based on searchField
      const searchField = searchBody.searchField
        ? searchBody.searchField
        : "olab_id";
      const sortBy = searchField === "merge" ? "olab_id" : searchField;

      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/charts/mergeFind",
        data: {
          search_type: "chart",
          search_string: searchBody.searchString ? searchBody.searchString : "",
          search_field: searchField,
          sort_by: sortBy,
          aux_id: null,
          page: searchBody.page ? searchBody.page : 1,
          limit: searchBody.limit ? searchBody.limit : OlabAxios.LIMIT_PER_PAGE
        }
      });

      // console.log("charts: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.charts
      ) {
        // console.log("charts: results = " + res.data.results);
        const chts = res.data.data.charts;
        // console.log("charts: chts =", chts);
        chts.forEach((cht) => {
          charts.push(new OlabItem(cht));
        });
      } else {
        console.log("charts is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCharts - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return charts;
  }

  // Special GET by olab_ids operation
  static async getCulturesByOlabIDs(olabIDs, statusObj) {
    // console.log("getCulturesByOlabIDs' olabIDs =", olabIDs);
    const cultures = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load charts
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/cultures/olabidsFind",
        data: {
          olab_ids: olabIDs
        }
      });

      // console.log("cultures: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.cultures
      ) {
        // console.log("cultures: results = " + res.data.results);
        const clts = res.data.data.cultures;
        // console.log("cultures: clts =", clts);
        clts.forEach((clt) => {
          cultures.push(new OlabItem(clt));
        });
      } else {
        console.log("cultures is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCharts - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return cultures;
  }

  // Special GET by emails operation
  static async getUsersByEmails(emails, statusObj) {
    // console.log("getUsersByEmails' emails =", emails);
    const users = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load users
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/users/emailsFind",
        data: {
          emails: emails
        }
      });

      // console.log("users: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.users
      ) {
        // console.log("users: results = " + res.data.results);
        const usrs = res.data.data.users;
        // console.log("users: usrs =", usrs);
        usrs.forEach((usr) => {
          users.push(new OlabItem(usr));
        });
      } else {
        console.log("users is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getUsersByEmails - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return users;
  }

  // Special GET by olab_ids operation
  static async getExperimentsByOlabIDs(olabIDs, statusObj) {
    // console.log("getExperimentsByOlabIDs' olabIDs =", olabIDs);
    const experiments = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load experiments
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/experiments/olabidsFind",
        data: {
          olab_ids: olabIDs
        }
      });

      // console.log("experiments: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.experiments
      ) {
        // console.log("experiments: results = " + res.data.results);
        const exprts = res.data.data.experiments;
        // console.log("experiments: exprts =", exprts);
        exprts.forEach((exprt) => {
          experiments.push(new OlabItem(exprt));
        });
      } else {
        console.log("experiments is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getExperimentsByOlabIDs - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return experiments;
  }

  // Special GET by olab_ids operation
  static async getMediasByOlabIDs(olabIDs, statusObj) {
    // console.log("getMediasByOlabIDs' olabIDs =", olabIDs);
    const medias = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load medias
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/medias/olabidsFind",
        data: {
          olab_ids: olabIDs
        }
      });

      // console.log("medias: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.medias
      ) {
        // console.log("medias: results = " + res.data.results);
        const mdas = res.data.data.medias;
        // console.log("medias: mdas =", mdas);
        mdas.forEach((mda) => {
          medias.push(new OlabItem(mda));
        });
      } else {
        console.log("medias is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getMediasByOlabIDs - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return medias;
  }

  // Special GET by olab_ids operation
  static async getStrainsByOlabIDs(olabIDs, statusObj) {
    // console.log("getStrainsByOlabIDs' olabIDs =", olabIDs);
    const strains = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    // Load strains
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/strains/olabidsFind",
        data: {
          olab_ids: olabIDs
        }
      });

      // console.log("strains: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.strains
      ) {
        // console.log("strains: results = " + res.data.results);
        const strns = res.data.data.strains;
        // console.log("strains: strns =", strns);
        strns.forEach((strn) => {
          strains.push(new OlabItem(strn));
        });
      } else {
        console.log("strains is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getStrainsByOlabIDs - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return strains;
  }

  static async getStrainCandidates(experimentID, cultureStage, statusObj) {
    OlabAxios.setStatusObjSearch(true, statusObj);
    let strains = [];
    // Convert to a 2 digit string
    cultureStage = cultureStage.toString().padStart(2, "0");

    // Load charts
    try {
      // POST because we are sending a body with this request
      const res = await axios({
        method: "POST",
        url: "/api/v1/cultures/strainCandidates",
        data: {
          experiment_id: experimentID,
          culture_stage: cultureStage
        }
      });

      // console.log("strains: status = " + res.data.status);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.strains
      ) {
        // console.log("strains: results = " + res.data.results);
        strains = res.data.data.strains;
        // console.log("strains: strns =", strns);
      } else {
        console.log("strains is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCharts - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return strains;
  }

  static async getProjectMembers(projectID, statusObj) {
    // console.log("OlabAxios.getProjectMembers: projectID =", projectID);
    let members = [];
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios.get(`/api/v1/projects/getMembers/${projectID}`);
      if (
        res.data.status === "success" &&
        res.data.data &&
        res.data.data.members
      ) {
        members = res.data.data.members;
        // console.log("members: members =", members);
      } else {
        console.log("members is null");
      }
    } catch (err) {
      OlabUtils.errorLog("getProjectMembers - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return members;
  }

  // A separate of saveItem for Chart to update chart's control flow data
  static async updateChart(user, chart, statusObj) {
    // console.log("OlabAxios.updateChart (chart = ", chart, ", user =", user);
    // Set progress status
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && chart) {
      try {
        // TODO: veridate Control data if possible
        const res = await axios({
          method: "PATCH",
          url: `/api/v1/charts/${chart.olab_id}`,
          data: {
            name: chart.name,
            // READ ONLY Properties, once set, for now
            // source: chart.source,
            note: chart.note,
            verified_date: chart.verified_date,
            desc: chart.desc,
            control: chart.control,
            // In case there is a need to clear svg_filenames
            svg_filenames: chart.svg_filenames,
            auto_range: chart.auto_range,
            // READ ONLY Properties for interal use only
            // left_y_axes_limits: chart.left_y_axes_limits,
            // right_y_axes_limits: chart.right_y_axes_limits,
            updated_by: user.email
          }
        });
        // console.log(res.data.data.chart);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          chart.updatedAt = res.data.data.chart.updatedAt;
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  static async generateSVGFile(user, chart, statusObj) {
    // console.log("generateSVGFile: chart =", chart);
    OlabAxios.setStatusObjProgress(true, statusObj);
    if (user && chart) {
      try {
        const res = await axios({
          method: "POST",
          url: `/api/v1/charts/generateSVGFile/${chart.olab_id}`
        });
        // console.log(res.data.data.chart);
        if (res.data.status === "success") {
          // console.log("Document successfully written!");
          chart.svg_filenames = res.data.data.chart.svg_filenames;
          chart.left_y_axes_limits = res.data.data.chart.left_y_axes_limits;
          chart.right_y_axes_limits = res.data.data.chart.right_y_axes_limits;
          chart.updatedAt = res.data.data.chart.updatedAt;
        }
      } catch (err) {
        // console.error("Error writing document: ", err);
        statusObj.errorMessage = OlabUtils.getErrorMessage(err);
      }
    }
    // Done! Reset progress status
    statusObj.progress = false;
  }

  static async getCombinedProdDataCSVFile(item, statusObj) {
    // console.log("getCombinedProdDataCSVFile: item =", item);
    let combCSV = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "POST",
        url: `/api/v1/cultures/getCombinedProductionData/${item.olab_id}`,
        data: {
          olab_id: item.olab_id,
          creator: item.creator
        }
      });

      // console.log(res);
      if (res.status === 200 && res.data) {
        combCSV = res.data;
      } else {
        console.log("culture's combined production data CSV is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCSVFile - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return combCSV;
  }

  static async getChartCSVFile(item, statusObj) {
    // console.log("getChartCSVFile: item =", item);
    let chartCSV = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/charts/getCSVFile/${item.olab_id}`
      });

      // console.log(res);
      // console.log();
      // console.log(res.data);
      // if (res.statusText === "OK" && res.data) { // statusText may be dropped in the future
      if (res.status === 200 && res.data) {
        chartCSV = res.data;
      } else {
        console.log("chart's CSV is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getCSVFile - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return chartCSV;
  }

  // TODO: Need to support multiple SVG files per CHart case
  // TODO: Add witdth and height of SVG image
  static async getSVGFile(item, svgWidth, svgHeight, statusObj) {
    // console.log("getSVGFile: item =", item);
    let chartSVG = null;
    OlabAxios.setStatusObjSearch(true, statusObj);

    try {
      const res = await axios({
        method: "GET",
        url: `/api/v1/charts/getSVGFile/${item.olab_id}`
      });

      // console.log(res);
      // console.log();
      // console.log(res.data);
      // if (res.statusText === "OK" && res.data) { // statusText may be dropped in the future
      if (res.status === 200 && res.data) {
        chartSVG = res.data;
        // TODO: Set SVG's (width, height) to (svgWidth, svgHeight)
        // const verIndex = chartSVG.indexOf("version");
        // console.log("verIndex =", verIndex);
        // const strVer = chartSVG.substring(6, 6 + 7);
        // console.log("strVer =", strVer);

        // const widthIndex = chartSVG.indexOf("width=");
        // console.log("widthIndex =", widthIndex);
        // const widthStr = chartSVG.substring(
        //   widthIndex,
        //   widthIndex + "width".length
        // );
        // console.log("widthStr =", widthStr);
        // const widthNumIndex = widthIndex + "width".length + 2;
        // const widthNumEndIndex = chartSVG.indexOf("pt", widthNumIndex);
        // const widthNumStr = chartSVG.substring(widthNumIndex, widthNumEndIndex);
        // console.log(widthNumEndIndex - widthNumIndex);
        // console.log("widthNumStr =", widthNumStr);

        // const heightIndex = chartSVG.indexOf("height=");
        // console.log("heightIndex =", heightIndex);
        // const heightStr = chartSVG.substring(
        //   heightIndex,
        //   heightIndex + "heigth".length
        // );
        // console.log("heightStr =", heightStr);
        // const heightNumIndex = heightIndex + "heigth".length + 2;
        // const heightNumEndIndex = chartSVG.indexOf("pt", heightNumIndex);
        // const heightNumStr = chartSVG.substring(
        //   heightNumIndex,
        //   heightNumEndIndex
        // );
        // console.log(heightNumEndIndex - heightNumIndex);
        // console.log("heightNumStr =", heightNumStr);

        // const origWidth = parseFloat(widthNumStr);
        // const origHeight = parseFloat(heightNumStr);
        // console.log(`(width, height) = (${origWidth}, ${origHeight})`);
      } else {
        console.log("chart's SVG is null");
      }
    } catch (err) {
      // OlabUtils.errorLog("** getSVGFile - Error:", err);
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset search status
    statusObj.searching = false;
    return chartSVG;
  }

  static async getSystemStats(statusObj) {
    statusObj.message =
      "Processing memory usage may take a while. Please wait ...";
    statusObj.progress = true;
    // Load sysStats
    let sysStats = null;
    try {
      const res = await axios.get("/api/v1/stats/systemstats");
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("system's stats = ", res.data.data);
        sysStats = res.data.data;
      } else {
        console.log("System's stats is null");
      }
    } catch (err) {
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return sysStats;
  }

  static async getProjectStats(projID, fromDate, toDate, statusObj) {
    statusObj.message =
      "Processing memory usage may take a while. Please wait ...";
    statusObj.progress = true;
    // Load projStats
    let projStats = null;
    try {
      const dates = {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      };
      const res = await axios.get(
        `/api/v1/stats/projectstats/${projID}`,
        dates
      );
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("project's stats = ", res.data.data);
        projStats = res.data.data;
      } else {
        console.log("Project's stats is null");
      }
    } catch (err) {
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return projStats;
  }

  static async getUserStats(userID, fromDate, toDate, statusObj) {
    statusObj.message =
      "Processing memory usage may take a while. Please wait ...";
    statusObj.progress = true;
    // Load userStats
    let userStats = null;
    try {
      const dates = {
        params: {
          from_date: fromDate,
          to_date: toDate
        }
      };
      const res = await axios.get(`/api/v1/stats/userstats/${userID}`, dates);
      if (res.data.status === "success" && res.data.data) {
        // console.log("data.results = ", res.data.results);
        // console.log("user's stats = ", res.data.data);
        userStats = res.data.data;
      } else {
        console.log("User's stats is null");
      }
    } catch (err) {
      statusObj.errorMessage = OlabUtils.getErrorMessage(err);
    }
    // Done! Reset progress status
    statusObj.message = null;
    statusObj.progress = false;
    return userStats;
  }
}

export { OlabAxios };
